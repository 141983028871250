.intro {
  font-size: 22px;
  text-align: center;
  color: #808080;
  padding: 0 70px;
  margin-bottom: 60px;
}

.product-container {
  margin-bottom: 40px;
  .infos {
    padding: 30px 20px 30px 50px;
    background-color: #fff;
  }
  .img_container {
    height: 320px;
  }
  .state {
    background-color: #db9d02;
    color: #fff;
      @extend %flex;
    align-self: flex-start;
    justify-content: flex-start;
  }
}
.description{
  font-size: 16px;
  @extend %font_default_light;
  line-height: 20px;
}
.desc-italic{
  font-size: 18px;
  @extend %font_default_light;
  line-height: 20px;
  font-style: italic;
}
.badge {
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  text-transform: uppercase;
  padding: 18px 25px;
  border-radius: 0;
}

.overlay {
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
    @extend %flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.hover {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
  transition: all 250ms ease-in-out 0s;
}

.icon-plus:before {
  content: "+";
  color: #fff;
  font-size: 37px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  display: block;
  border: 1px solid #fff;
  -webkit-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
  transition: all 250ms ease-in-out 0s;
}

.product-link {
  color: #30220f;
  &:hover, &:focus {
    color: #30220f;
  }
  &:hover .hover, &:focus .hover {
    opacity: 1;
    top: 15px;
    right: 15px;
    left: 15px;
    bottom: 15px;
  }
  &:hover .icon:before, &:focus .icon:before {
    opacity: 1 !important;
  }
  &:hover .icon:after, &:focus .icon:after {
    opacity: 0 !important;
  }
  .state .icon-travaux:after, .liste-triangle li:after {
    opacity: 1 !important;
  }
  &:active .icon-next-big {
    right: -10px;
  }
}

.btn-modal-contact {
  background-color: transparent;
  border: none;
  color: #fff;
  padding: 0;
  &:hover {
    color: #30220f;
  }
}

.bottom-product {
  background-color: #c57a11;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 30px;
}

.lending {
  @extend %flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  margin-right: -1px;
  position: relative;
  .icon {
    height: 40px;
    margin-bottom: 15px;
  }
  &.lending-x2{
    width: 160px;
  }
}

.lending-title {
  color: #fff;
  font-size: 14px;
  line-height: 1em;
  text-transform: uppercase;
  position: absolute;
  bottom: 8px;
}

.icon-tva {
  height: 40px;
  -webkit-transform: rotate(-13deg);
  -moz-transform: rotate(-13deg);
  -ms-transform: rotate(-13deg);
  -o-transform: rotate(-13deg);
  transform: rotate(-13deg);
  position: relative;
  left: 3px;
  bottom: 2px;
  &:before, &:after {
    color: #fff;
    font-size: 24px;
  }
  &:before {
    content: attr(data-number);
  }
  &:after {
    content: "%";
  }
}

.btn-sticky-contact {
  position: fixed;
  bottom: 0;
  right: 20px;
  background-color: #30220f;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  -webkit-align-items: center;
  align-items: center;
  padding: 13px 20px;
  z-index: 50;
  .icon {
    margin-right: 10px;
  }
  &.fixed {
    position: absolute;
    top: inherit;
  }
  &:hover, &:active {
    background-color: #b96e14;
  }
}

.modal-content {
  border-radius: 0;
}

button.close {
  position: absolute;
  right: -40px;
  top: 0;
  background-color: #30220f;
  color: #fff;
  opacity: 1;
  width: 40px;
  height: 40px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  &:hover, &:focus {
    color: #fff;
  }
}

.slider-detail .img_container {
  height: calc(100vh - 171px);
  min-height: 540px;
}
.bloc-slider{
  min-height: 297px;
  .infos{
  }
}
.fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.85);
}
.fs24{
  font-size: 24px;
}
.nav-close {
  top: -32px;
  right: -32px;
  width: 32px;
  height: 32px;
  background-color: #db9d02;
  &:after {
    content: '+';
    display: block;
    line-height: .65em;
    width: 34px;
    height: 34px;
    text-align: center;
    color: #000;
    position: relative;
    top: -1px;
    font-size: 46px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.fancybox-wrap {
  .slick-arrow {
    top: 50%;
    margin-top: -30px;
    text-align: center;
    position: absolute;
    cursor: pointer;
  }
  .slick-prev {
    left: -45px;
  }
  .slick-next {
    right: -45px;
  }
}

.fancybox-skin {
  border-radius: 0;
}

#fancybox-thumbs ul li {
  opacity: 1;
  &.active {
    opacity: 1;
    border: none;
    a:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(197, 122, 17, 0.8);
    }
  }
}

.slider-detail-container {
  position: relative;
}

.badge-detail {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 10;
  color: #fff;
}

.detail-padding {
  padding: 30px 0 80px;
}

.desc-container {
  position: relative;
  padding: 50px;
}

.btn-back {
  position: absolute;
  top: 0;
  left: -70px;
}

.desc-title {
  display: block;
  font-size: 28px;
  text-transform: uppercase;
  color: #d6d6d6;
  margin-bottom: 30px;
  line-height: 1em;
}
.desc-subtitle {
  display: block;
  font-size: 24px;
  font-style: italic;
  margin-bottom: 20px;
  line-height: 1em;
}

.desc-text {
  font-size: 20px;
}

.infos-container {
  height: 100%;
  .flexColumn #captcha {
    transform: scale(0.96) translateX(-2%);
    margin-bottom: 20px;
  }
}

.flexColumn #captcha {
  margin-bottom: 20px;
  div {
    margin: 0 auto;
  }
}

.fix-element {
  width: 350px;
}

.fixed {
  position: fixed;
  top: 70px;
}

.unfixed {
  position: absolute;
  bottom: 0;
}

.lendings {
  @extend %flex;
}
.lendings-orange {
  .icon:after {
    opacity: 1;
  }
  .lending {
    border-color: #b96e14;
  }
  .lending-title {
    color: #b96e14;
  }
  .icon-tva {
    &:after, &:before {
      color: #b96e14;
    }
  }
}

.bloc {
  margin-bottom: 70px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.btn-plaquette {
  background-color: #b96e14;
  padding: 15px 35px;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 22px;
  flex: 0 0 auto;
  .icon {
    margin-right: 20px;
  }
  &:hover, &:focus {
    color: #fff;
    opacity: 0.8;
  }
  &:hover .icon:before, &:focus .icon:before {
    opacity: 1 !important;
  }
  &:hover .icon:after, &:focus .icon:after {
    opacity: 0 !important;
  }
}

.bloc-button-container {
  text-align: center;
}
.empty-dpe-container{
  @extend %flex;
  flex-wrap:wrap;
}
.no-dpe-eco,
.no-dpe-ges{
  background-image: url('../images/no-dpe-vierge');
  height: 211px;
  width: 260px;
}
.no-dpe-eco{
  background-position: 0 0;

}
.no-dpe-ges{
  background-position: 259px 0;

}
.download_map {
  padding: 10px 25px;
  margin: 15px auto 5px;
  display: -webkit-inline-flex;
  display: -ms-inline-flex;
  display: inline-flex;
  align-items: center;
  .fa {
    margin-right: 10px;
  }
  &:hover, &:focus {
    color: #fff;
  }
}

.list-dot {
  font-size: 20px;
  line-height: 30px;
  li:before {
    content: "•";
    margin-right: 10px;
    line-height: 30px;
    bottom: 1px;
    position: relative;
  }
}

.contact-yellow {
  background-color: #e9a600;
  .btn-mail {
    width: 100%;
    background-color: #30220f;
  }
  .input-container, .contact-textarea {
    margin-bottom: 18px;
  }
}

.fix-element {
  padding: 50px 30px;
}

.contact-detail-title {
  text-align: center;
  width: 100%;
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-size: 28px;
  margin-bottom: 35px;
}

.table {
  color: #fff;
  font-size: 18px;
  th {
    text-transform: uppercase;
  }
  tbody {
    tr {
      border-bottom: 1px solid #7a7a7a;
    }
    td {
      padding-top: 15px;
      padding-bottom: 15px;
      line-height: 1em !important;
      vertical-align: bottom !important;
    }
  }
}

.icon-plus-plan {
  color: #fff;
  &:before {
    content: "+";
    width: 30px;
    height: 30px;
    background-color: #e9a600;
    font-size: 30px;
    line-height: 26px;
    -webkit-justify-content: center;
    justify-content: center;
    border: 1px solid #fff;
    -webkit-transition: all 120ms ease-in-out 0s;
    -moz-transition: all 120ms ease-in-out 0s;
    -ms-transition: all 120ms ease-in-out 0s;
    transition: all 120ms ease-in-out 0s;
  }
}

.link-plan {
  display: block;
  background-color: transparent;
  padding: 0;
  border: none;
  &:hover .icon-plus-plan:before {
    background-color: #30220f;
  }
}

.legends {
  padding-top: 40px;
}

.legend {
  color: #fff;
  font-style: italic;
  font-size: 14px;
}

.detail {
  #map {
    height: 450px;
    border: 5px solid #fff;
    margin-bottom: 0;
  }
  .bloc-home {
    .liste-triangle {
      color: #fff;
      margin: 0;
      font-size: 18px;
    }
    &.color2 {
      .img_container:after, .b-container {
        background-color: #d08f05;
      }
    }
    &.color3 {
      .img_container:after, .b-container {
        background-color: #c57a11;
      }
    }
    &:hover .img_container:after, &:focus .img_container:after {
      height: 60px;
    }
  }
  .b-container {
    @extend %flex;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    min-height: 185px;
  }
}

.socials {
  margin-top: 45px;
}

.link-share {
  width: 60px;
  height: 60px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid #fff;
  margin-right: -1px;
  color: #fff;
  font-size: 24px;
  position: relative;
  &:before, &:after {
    opacity: 0;
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -ms-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  &:before {
    content: attr(data-original-title);
    position: absolute;
    top: -31px;
    white-space: nowrap;
    background-color: #fff;
    color: #30220f;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 15px;
    line-height: 1.1em;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  &:after {
    content: "";
    position: absolute;
    top: -6px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 5.5px 0 5.5px;
    border-color: #fff transparent transparent transparent;
  }
  &:hover, &:focus {
    color: #fff;
  }
  &:hover {
    &:before, &:after {
      opacity: 1;
    }
  }
  &:focus {
    &:before, &:after {
      opacity: 1;
    }
  }
}

.b-text {
  color: #fff;
  font-size: 18px;
}

.tel {
  color: #fff;
  .fa {
    margin-right: 10px;
  }
  &:hover, &:focus {
    color: #30220f;
  }
}

#modalContact .modal-dialog {
  max-width: 340px;
}

#modalPlan .contact-detail-title {
  color: #30220f;
}

.Immobilier .h1 {
  margin-bottom: 50px;
}

.box-section {
  margin-bottom: 50px;
  background-color: #fff;
}

.box-figure {
  height: 390px;
  width: 100%;
  background-size: cover;
    @extend %flex;
  align-items: flex-end;
}

.figure-acquisition {
  background-image: url("../images/acquisition.jpg");
}

.figure-investissement {
  background-image: url("../images/investissement.jpg");
}

.figure-title {
  color: #fff;
  margin-bottom: auto;
  position: relative;
  font-size: 36px;
  padding: 30px 60px 30px 50px;
  line-height: 42px;
  i {
    margin-right: 20px;
    padding-top: 8px;
  }
}

.title-container, .no-blend-mode .title-container {
  background-color: rgba(219, 157, 2, 0.75);
}

.isolate {
  isolation: isolate;
}

.accordeon-container {
  padding: 60px 70px 100px 90px;
  ul {
    padding-left: 20px;
  }
  li {
    list-style-type: disc;
    font-size: 18px;
    line-height: 24px;
  }
  .medium {
    font-weight: 500;
  }
}

.chapo {
  font-size: 20px;
  line-height: 26px;
}

.accordeon-title {
  margin-bottom: 35px;
  font-size: 24px;
}

.card {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #fff;
  background-color: #b96e14;
  color: #fff;
  &:last-of-type {
    border: none;
  }
  img {
    display: block;
    margin: 20px auto;
  }
}

.accordeon {
  border: 1px solid #db9d02;
  margin-bottom: 50px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.card-body {
  background-color: #fff;
  color: #30220f;
  font-size: 18px;
  line-height: 24px;
}

.card-header {
  padding: 0;
  a {
    padding: .75rem 1.25rem;
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    &:after {
      content: "_";
      font-size: 48px;
      position: absolute;
      right: 33px;
      top: -23px;
      line-height: 48px;
    }
    &.collapsed:after {
      content: "+";
      top: 0;
      right: 30px;
    }
  }
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $font_color_grey;
    font-family: "Muli", Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    @include font-size(18px);
    font-style: italic;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
    opacity: 1;
    /* Mozilla Firefox 4 to 18 */
    color: $font_color_grey;
    font-family: "Muli", Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    @include font-size(18px);
    font-style: italic;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    /* Mozilla Firefox 19+ */
    color: $font_color_grey;
    font-family: "Muli", Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    @include font-size(18px);
    font-style: italic;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $font_color_grey;
    font-family: "Muli", Helvetica, sans-serif;
    font-weight: 400;
    font-style: normal;
    @include font-size(18px);
    font-style: italic;
}
.noSelect {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
}
.locked{
    overflow:hidden;
}
.clear {
    clear: both;
    width: 100%;
}
.floatLeft {
    float: left!important;
}
.floatRight {
    float: right!important;
}

/*Récup' écrasement de fichier... */
.logo-link, .nav-link, .bloc-slider-container, .state, .bloc-arrows-container, .arrows-container, .btn-square, .b-container, .overlay, .hover, .lendings, .lending, .btn-sticky-contact, button.close, .btn-plaquette, .icon-plus-plan:before, .socials, .link-share, .box-figure, .figure-title {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
.title_bold, .link_head.tel, .state, .infos .title, .btn-default, .lending-title, .icon-tva:before, .btn-plaquette, .accordeon-title {
    font-family: "Ubuntu", Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
}
.link-share:before, .card-header a {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
}
.liste-triangle, .text, .contact-adresse, .intro, .icon-plus::before, .desc-title, .desc-text, .list-dot, .icon-plus-plan::before, .legend, .b-text, .figure-title, .card-body {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-weight: 300;
    font-style: normal;
}
%flex{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
}
%inline-flex{
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
}
.flexDistribution{
    @extend %flex;
    justify-content: space-between;
}
.flexAround{
    @extend %flex;
    justify-content: space-around;
}
.flexStart{
    @extend %flex;
    align-items: flex-start;
}
.flexColumn{
    @extend %flex;
    flex-direction: column;
}
.valign{
    @extend %flex;
    align-items:center;
    height: 100%;
}
.center-line {
    width: 100%;
    text-align: center;
}
sup {
    font-size: .6em !important;
}
.no-overflow {
    overflow: hidden !important;
}
.uppercase {
    text-transform: uppercase;
}
.btn {
    @include transition(all, 200ms, ease, 0s);
}
.fit{
    max-width: 100%;
    width: 100%;
}
.relative{
    position: relative !important;
}
.h100{
    height: 100%;
}

.table{
    margin-bottom: 0;
    display: table;
}
.table-cell{
    display: table-cell;
    height: 100%;
}
b,
strong{
  @extend %font_default_bold;
}
small{
  font-size: 14px;
}
.bold {
    @extend %font_default_bold;
}
.title_bold {
    @extend %font_title_bold;
}
.title_light {
    @extend %font_title_light;
}
.title_medium {
    @extend %font_title_medium;
}
.italic {
    font-style:italic;
}
.block{
    display: block;
}
.padd0{
    padding:0;
}
.paddR0{
    padding-right:0;
}
.paddL0{
    padding-left:0;
}
.underline{
    text-decoration: underline;
    &:hover{
      text-decoration: underline;
    }
}
.no-border {
    border: none !important;
}
.nowrap{
    white-space: nowrap;
    display: inline-block;
}
.vam{
    vertical-align: middle;
}
.p10{
    padding: 10px;
}
.p50{
    padding: 50px;
}
.p100{
    padding: 100px;
}
.pb120{
  padding-bottom: 120px;
}
.mr80{
  margin-right: 80px;
}
.mb0{
    margin-bottom: 0;
}
.mb10{
    margin-bottom: 10px;
}
.mb15{
    margin-bottom: 15px;
}
.mb20{
    margin-bottom: 20px;
}
.mb25{
    margin-bottom: 25px;
}
.mb30{
    margin-bottom: 30px;
}
.mb40{
    margin-bottom: 40px;
}
.mb50{
    margin-bottom: 50px;
}
.mb60{
    margin-bottom: 60px;
}
.mb70{
    margin-bottom: 70px;
}
.mb80{
    margin-bottom: 80px;
}
.mb100{
    margin-bottom: 100px;
}
.mb120{
    margin-bottom: 120px;
}
.mt10{
    margin-top: 10px;
}
.mt15{
    margin-top: 15px;
}
.mt20{
    margin-top: 20px;
}
.mt25{
    margin-top: 25px;
}
.mt30{
    margin-top: 30px;
}
.mt35{
    margin-top: 35px;
}
.mt45{
    margin-top: 45px;
}
.mt70{
    margin-top: 70px;
}
.mt80{
    margin-top: 80px;
}
.mt90{
    margin-top: 90px;
}
.mt100{
    margin-top: 100px;
}

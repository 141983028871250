/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: none;
  background: transparent;
  position: relative;
  border-radius: 0px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 0px;
  padding: 0 15px;
  border:1px solid #fff;
}

.selectric .button {
  display: block;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.selectric .button:after {
  content: "\f107";
  font-family: 'fontAwesome';
  color:$font_color_dark2;
  font-size: 16px;
  left: 0;
  bottom: 0;
  position: absolute;
}

.selectric-focus .selectric {
  border-color: #AAA;
}
// .selectric-hover .selectric {
//   border-color: $bg_color_bluelight;
// }
//
// .selectric-hover .selectric .button {
//   color: $bg_color_bluelight;
// }
//
// .selectric-hover .selectric .button:after {
//   border-top-color: $bg_color_bluelight;
// }
// .selectric-open .selectric {
//   border-color: $bg_color_bluelight;
//   background: $bg_color_blue;
// }

.selectric-open {
  z-index: 9999;
}


.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  min-height: 20px;
  border-radius: 0px;
}

.selectric-items li {
  display: block;
  padding: 8px;
  // border-top: 1px solid #FFF;
  // border-bottom: 1px solid #EEE;
  color: #666;
  cursor: pointer;
}

.selectric-items li.selected {
  background: #EFEFEF;
  color: #444;
}

.selectric-items li.highlighted {
  //background: $bg_color_blue;
  color: $font_color_white;
}

.selectric-items li:hover {
  //background: $bg_color_bluelight;
  color: $font_color_white;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}

/*======================================
  iCheck
======================================*/
.icheckbox,
.iradio {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
}
.icheckbox{
	background-color: $bg_color_white;
	text-align: center;
	margin-right: 15px;
	&.checked{
		&:after{
			content:"\f00c";
			font-family: "fontAwesome";
			@include font-size(18px);
			//color: $font_color_bluelight;
		}
	}
}

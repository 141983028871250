// $font_default_regular : 'GothamBook', Helvetica, Sans-serif;
// $font_default_bold : 'gothambold1', Helvetica, Sans-serif;
// $font_default_light : 'GothamLight', Helvetica, Sans-serif;
// $font_text_light : 'Roboto Light', Helvetica, Sans-serif;
// $font_text_medium : 'Roboto', sans-serif;

@mixin font($family, $weight: 400, $style: normal) {
  font-family: $family, Helvetica, sans-serif;
  font-weight: $weight;
  font-style: $style;
}

%font_title_regular {
  @include font('Ubuntu', 400);
}
%font_title_medium {
  @include font('Ubuntu', 500);
}
%font_title_bold {
  @include font('Ubuntu', 700);
}
%font_title_light{
  @include font('Ubuntu', 300);
}
%font_default_regular {
  @include font("Source Sans Pro", 400);
}
%font_default_bold {
  @include font("Source Sans Pro", 700);
}
%font_default_light{
  @include font("Source Sans Pro", 300);
}

//Couleurs police
$font_color_white: #fff;
$font_color_dark: #000;
$font_color_dark2: #30220f;
$font_color_grey: #808080;
$font_color_grey2: #d6d6d6;
$font_color_grey3: #8c8c8c;
$font_color_yellow: #e9a600;
$font_color_orange: #db9d02;
$font_color_orange2: #b96e14;

//Page style
$bg_color_white: #fff;
$bg_color_offwhite: #f3f3f3;
$bg_color_dark2: #30220f;
$bg_color_grey: #a9a296;
$bg_color_grey2: #7a7a7a;
$bg_color_darkgrey: #656565;
$bg_color_yellow: #e9a600;
$bg_color_yellow2: #d08f05;
$bg_color_orange:#db9d02;
$bg_color_orange2:#b96e14;
$bg_color_orange3:#c57a11;
$bg_color_greylight:#ececec;
$bg_color_grey:#d6d6d6;


//Variables réseaux sociaux
$facebook: #3b5998;
$twitter: #32ccfe;
$google-plus: #d2352c;
$linkedin: #007bb5;
$youtube: #ed0800;
$rss: #ff6e00;

//Tableau réseaux sociaux
$socialColors : (
  facebook: #3b5998,
  twitter: #32ccfe,
  google-plus: #d2352c,
  linkedin: #007bb5,
  youtube: #ed0800,
  rss: #ff6e00
);

@each $socialName, $socialColor in $socialColors  {
	.#{$socialName}{
    color:$font_color_white;
		&:hover{
      color:#{$socialColor};
		}
	}
}

//Divers
$radius: 3px;

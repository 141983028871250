//Icônes
.icon:not(.icon-3clics){
  position: relative;
  display: inline-block;
  right: 0;
  @include transition(all,120ms,ease-in-out,0s);
  &:before,
  &:after{
    content:"";
    display: block;
    @include transition(all,120ms,ease-in-out,0s);
  }
  &:before{
    background-position: 0 0;
  }
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
a{
  &:hover,
  &:focus{
    .icon:not(.icon-3clics){
      &:before{
        opacity: 0;
      }
      &:after{
        opacity: 1;
      }
    }
  }
}
$icons: (triangle,16,12),
        (logo,18,15),
        (travaux,26,20),
        (next-big,44,50),
        (next-medium,21,24),
        (next,13,16),
        (prev,13,16),
        (list-triangle,10,12),
        (pinel,36,37),
        (ptz,49,37),
        (rt2012,49,30),
        (piece,43,44),
        (chambre,44,38),
        (salle-de-bain,38,39),
        (surface,38,38),
        (place,40,40),
        (listing,45,45),
        (envelope,30,19),
        (back,45,45),
        (plaquette,43,39),
        (honoraires,45,40),
        (search,24,25);
@each $icon, $icon-width, $icon-height in $icons {
  .icon-#{$icon}{
    &:before,
    &:after{
      width: #{$icon-width}px;
      height: #{$icon-height}px;
      background-image: url('../images/icones/icon-#{$icon}.png');
    }
    &:after{
      background-position:0 #{$icon-height}px;
    }
  }
}

/*Burger Icon*/
.burger{
  width: 30px;
  height: 30px;
  position: relative;
  margin: 30px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  border:none;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $bg_color_orange;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }
    &:nth-child(4) {
      top: 20px;
    }
  }
  &.open{
    span{
      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
.responsive-nav-container{
  height: 100%;
  position: relative;
  background-color: transparent;
  @include transition(all,250ms,ease-in-out,0s);
}
.header{
  padding-bottom: 139px;
}
.header-top{
  background-color: $bg_color_orange;
  .fa,
  .icon{
    margin-right: 10px;
  }
  .flexAround{
    -webkit-align-items: center;
    align-items: center;
    height: 32px;
  }
}
.link_head{
  @extend %font_title_regular;
  color:$font_color_white;
  text-transform: uppercase;
  @include font-size(14px);
  &:hover,
  &:focus{
    color:$font_color_dark2;
  }
  &.tel{
    @extend %font_title_bold;
    @include font-size(18px);
  }
}
.connexion {
  @extend %font_title_regular;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 10px;
}
.logo-link{
  @include transition(all,250ms,ease-in-out,0s);
  width: 304px;
  overflow: hidden;
  margin-right: 30px;
  height: 90px;
  @extend %flex;
  -webkit-justify-content: start;
  justify-content: start;
  align-items: center;
  .logo{
    width: 304px;
    @include transition(all,250ms,ease-in-out,0s);
  }
}
.nav{
  flex-wrap: nowrap;
}

.logo-container{
  width: 304px;
  margin-right: 30px;
  @include transition(all,250ms,ease-in-out,0s);
}
.header-bottom{
  background-color: rgba(255,255,255,1);
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 50;
  width: 100%;
  @include transition(background-color,250ms,ease-in-out,0s);
  .flexAround{
    -webkit-align-items: center;
    align-items: center;
    height: 139px;
    @include transition(all,250ms,ease-in-out,0s);
  }
  &.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,0.9);
    .main-nav-container,
    .nav{
      height: 100%;
    }
    .nav-link{
      padding-bottom: 0;
      padding-top: 0;
    }
    .flexAround{
      height: 70px;
    }
    .logo-container{
      .logo-link{
        height: auto;
        height: 52px;
        width: 70px;
      }
      .logo{
        flex:none;
        height: auto;
        width: 175px;
      }
    }
  }
}
.burger,
.exit_nav,
.back_nav{
  display: none;
}
.main-nav-container{
  -webkit-align-self: flex-end;
  align-self: flex-end;
}
.active{
  background-color: $bg_color_greylight;
  border-bottom: 6px solid $bg_color_grey;
}
.nav-link{
  height: 100%;
  text-align: center;
  align-items: center;
  @extend %flex;
  @extend %font_title_medium;
  @include font-size(15px);
  line-height: 20px;
  text-transform: uppercase;
  color:$font_color_dark2;
  padding-bottom: 25px;
  margin-left: 40px;
  &:hover,
  &:focus{
    color:$font_color_yellow;
  }
  .icon-logo:after {
    background-position: 0 30px;
  }
}
.item-nav{
  &:first-of-type{
    .nav-link{
      margin-left: 0;
    }
  }
}
//footer
.footer{
  background-color:$bg_color_orange2;
  color:$font_color_white;
  padding: 35px 0;
  @extend %font_title_light;
}
.footer-link,
.link_3clics{
  text-transform: uppercase;
  color:$font_color_white;
  &:hover,
  &:focus{
    color:$font_color_dark2;
  }
}
.link_3clics{
  @include font-size(25px);
}

@media (max-width: 1199px) {
  .nav-link {
    padding: 5px 8px 20px;
    margin-left: 10px;
    font-size: 14px;
  }
  .b-title {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .burger {
    display: block;
    z-index: 50;
  }
  .unfixed,
  .fixed{
    position: relative;
    top: 0;
  }
  .contact-yellow{
    width: 100%;
  }

  .responsive-nav-container {
    opacity: 0;
    &.visible {
      opacity: 1;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 230px;
    background-color: #db9d02;
    flex-direction: column;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 15px 0;
    border-right: 3px solid #c57a11;
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -ms-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;
    &.visible {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
    }
    .nav-link {
      color: #fff;
      text-align: left;
      padding: 10px 10px 10px 20px;
      margin-left: 0px;
      .icon-logo {
        &:after, &:before {
          background-position: 0 -30px;
        }
      }
      &.active {
        color: #30220f;
        .icon-logo {
          &:after, &:before {
            background-position: 0 0px;
          }
        }
      }
    }
  }
  .social-container .connexion {
    display: none;
  }
  .link_head {
    font-size: 12px;
  }
  .header {
    padding-bottom: 0;
  }
  .header-filler {
    height: 0;
  }
  .header-bottom {
    position: relative;
    top: 0;
    .flexAround {
      height: 100px;
    }
    &.fixed {
      .burger {
        margin: 20px auto;
      }
      .nav-link {
        padding: 10px 10px 10px 20px;
      }
      + .header-filler {
        height: 70px;
      }
    }
  }
  .logo-link {
    width: 250px;
    height: auto;
    .logo {
      width: 250px;
    }
  }
  .header-top .flexAround {
    flex-wrap: wrap;
  }
  .active {
    border-bottom: none;
    border-right: 6px solid #d6d6d6;
  }
  .liste-triangle {
    font-size: 16px;
  }
  .product-container .infos {
    padding: 30px 20px;
  }
  .bloc-slider {
    padding: 15px 45px 15px 30px;
  }
  .bloc-arrows-container {
    position: relative;
    bottom: 0;
    right: 0;
    .link-detail {
      position: relative;
      right: 0;
      bottom: 0;
      margin-right: 15px;
    }
  }
  .intro {
    padding: 0 15px;
  }
  .lending {
    width: 50px;
    height: 50px;
    .icon {
      -webkit-transform: scale(0.6);
      -moz-transform: scale(0.6);
      -ms-transform: scale(0.6);
      -o-transform: scale(0.6);
      transform: scale(0.6);
    }
  }
  .lending-title {
    font-size: 12px;
    bottom: 4px;
  }
  .icon-tva {
    bottom: -2px;
    &:before, &:after {
      font-size: 16px;
    }
  }
  .horaires {
    margin-bottom: 50px;
  }
  .bloc-home .img_container {
    height: 250px;
  }
  .b-container {
    padding: 15px;
  }
  .b-title {
    font-size: 18px;
    line-height: 24px;
  }
  .bottom-bloc-contact {
    padding: 0 15px 30px;
  }
  .contact-form {
    .flexDistribution {
      flex-direction: column;
    }
    .btn-default {
      margin-top: 20px;
    }
  }
  .nav-close{
    right: 0;
  }
  .fancybox-wrap {
    .slick-next{
      right: 0;
    }
    .slick-prev{
      left: 0;
    }
  }
}

@media (min-width: 768px) {
  .btn-top-menu-responsive, .back-btn-top-menu-responsive {
    display: none;
  }
}

@media (max-width: 767px) {
  button.close{
    right: 0;
    z-index: 5;
  }
  .logo-container {
    width: 200px;
  }
  .logo-link {
    width: 200px;
    height: auto;
    .logo {
      width: 200px;
    }
  }
  .btn-top-menu-responsive, .back-btn-top-menu-responsive {
    display: block;
    background-color: transparent;
    color: #fff;
    text-align: left;
    padding: 10px 10px 10px 20px;
    text-transform: uppercase;
    font-size: 14px;
    border: none;
  }
  .btn-top-menu-responsive .fa {
    margin-right: 10px;
  }
  .back-btn-top-menu-responsive .fa {
    margin-left: 10px;
  }
  .header-top .flexAround {
    height: 100%;
    align-items: left;
    justify-content: normal;
  }
  .header-top-responsive-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 230px;
    background-color: #db9d02;
    flex-direction: column;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    padding: 15px 0;
    border-right: 3px solid #c57a11;
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -ms-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;
    &.visible {
      -webkit-transform: translateX(0%);
      -moz-transform: translateX(0%);
      -ms-transform: translateX(0%);
      -o-transform: translateX(0%);
      transform: translateX(0%);
      z-index: 60;
    }
    .link_head, .social-container {
      padding: 10px 10px 10px 20px;
      font-size: 14px;
    }
  }
  .bloc-home {
    margin-bottom: 30px;
  }
  .bloc-slider {
    padding: 15px;
  }
  .slider-home .img_container {
    min-height: 440px;
  }
  .state {
    width: 100%;
    justify-content: center;
  }
  .bloc-slider-container {
    right: 15px;
  }
  .text {
    font-size: 18px;
  }
  .h1 {
    font-size: 30px;
  }
  .contact-adresse {
    font-size: 24px;
    line-height: 28px;
  }
  .footer .flexDistribution {
    flex-direction: column;
    .left {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      text-align: center;
      span {
        margin-bottom: 15px;
      }
    }
  }
  .mr80 {
    margin-right: 0;
  }
  .desc-container{
    padding: 10px;
  }
  .table td:before {
    content: attr(data-title);
  }
  .table table,
  .table thead,
  .table tbody,
  .table th,
  .table td,
  .table tr {
    display: block;
  }
  .table thead tr {
    display: none;
  }
  .table td {
    position: relative;
    min-height: 35px;
    padding-left: 50% !important;
    text-align: right;
    white-space: normal;
    border: 0 solid transparent;
  }
  .table td:nth-of-type(7n) {
    background-color: #ebf3f5;
  }
  .table td:before {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden !important;
    width: 45%;
    height: 100%;
    padding: 8px;
    padding-right: 15px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    background-color: transparent;
  }
  .accordeon-container{
    padding: 15px;
  }
  .card-header a{
    padding-right: 30px;
    &:after{
      content:"-";
      font-size: 24px;
      right: 10px;
      top: 0;
    }
    &.collapsed::after {
      right: 10px;
    }
  }
  .figure-title{
    font-size: 30px;
    line-height: 34px;
  }
}

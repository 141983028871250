@mixin transition($target, $duration, $easing, $delay) {
    -webkit-transition: $target $duration $easing $delay;
       -moz-transition: $target $duration $easing $delay;
        -ms-transition: $target $duration $easing $delay;
            transition: $target $duration $easing $delay;
}

@mixin border-radius($pixel) {
    -webkit-border-radius: $pixel;
       -moz-border-radius: $pixel;
            border-radius: $pixel;

        -ms-border-radius: $pixel;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: '') {
    -webkit-box-shadow: $top $left $blur $color #{$inset};
       -moz-box-shadow: $top $left $blur $color #{$inset};
            box-shadow: $top $left $blur $color #{$inset};
}

@mixin transform($transforms) {
    -webkit-transform: $transforms;
       -moz-transform: $transforms;
        -ms-transform: $transforms;
         -o-transform: $transforms;
            transform: $transforms;
}
@mixin transform-origin ($origin) {
    -webkit-transform-origin: $origin;
       -moz-transform-origin: $origin;
        -ms-transform-origin: $origin;
         -o-transform-origin: $origin;
            transform-origin: $origin;
}

@mixin opacity($opacity) {
    opacity: $opacity;

    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie);
}
//Scale
@mixin scale($echelle) {
    @include transform(scale(#{$echelle}echelle));
}

//Rotation
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

//Font size
@function calculateRem($size) {
    $remSize: $size / 10px;
    @return $remSize * 1rem;
}
@mixin font-size($size) {
    font-size: $size;
    //font-size: calculateRem($size);
}
@-webkit-keyframes ease-in-back {
    0% { right: -120%;}
    70% {right:15%}
    100% {
       right: 5%;
    }
}
@-moz-keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}
@-o-keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}
@keyframes ease-in-back {
  0%   { right: -120%; }
  70% {right:15%}
  100% {right: 5%; }
}

@mixin ease-in-back($duration) {
    -webkit-animation:ease-in-back $duration 2s forwards;
    -moz-animation:ease-in-back $duration 2s forwards;
    -ms-animation:ease-in-back $duration 2s forwards;
    animation:ease-in-back $duration 2s forwards;
}

/*Pop IN effect*/
@-webkit-keyframes pop-in {
    0% {
        -webkit-transform: scale(.1);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}
@-moz-keyframes pop-in {
    0% {
        -moz-transform: scale(.1);
        opacity: 0;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 1;
    }
}
@keyframes pop-in {
    0% {
        transform: scale(.1);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@mixin pop-in($duration) {
    -webkit-animation:pop-in $duration;
    -moz-animation:pop-in $duration;
    -ms-animation:pop-in $duration;
    animation:pop-in $duration;
}

/*Pop IN effect*/
@-webkit-keyframes pop-out {
    0% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(.1);
        opacity: 0;
    }
}
@-moz-keyframes pop-out {
    0% {
        -moz-transform: scale(1);
        opacity: 1;
    }
    100% {
        -moz-transform: scale(.1);
        opacity: 0;
    }
}
@keyframes pop-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(.1);
        opacity: 0;
    }
}

@mixin pop-out($duration) {
    -webkit-animation:pop-out $duration;
    -moz-animation:pop-out $duration;
    -ms-animation:pop-out $duration;
    animation:pop-out $duration;
}
@-webkit-keyframes fadeInLeft {
    0% {
        -webkit-transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInLeft {
    0% {
        -moz-transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        -moz-transform: translateX(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInLeft {
    0% {
        -o-transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        -o-transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInLeft {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@mixin fadeInLeft($object) {
    -webkit-animation:fadeInLeft $object;
    -moz-animation:fadeInLeft $object;
    -ms-animation:fadeInLeft $object;
    animation:fadeInLeft $object;
}
@-webkit-keyframes fadeInRight {
    0% {
        -webkit-transform: translateX(50%);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        opacity: 1;
    }
}

@-moz-keyframes fadeInRight {
    0% {
        -moz-transform: translateX(50%);
        opacity: 0;
    }

    100% {
        -moz-transform: translateX(0);
        opacity: 1;
    }
}

@-o-keyframes fadeInRight {
    0% {
        -o-transform: translateX(50%);
        opacity: 0;
    }

    100% {
        -o-transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInRight {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@mixin fadeInRight($object) {
    -webkit-animation:fadeInRight $object;
    -moz-animation:fadeInRight $object;
    -ms-animation:fadeInRight $object;
    animation:fadeInRight $object;
}

@-webkit-keyframes fadeOutRight {
    0% {
      -webkit-transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
}

@-moz-keyframes fadeOutRight {
    0% {
      -webkit-transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
}

@-o-keyframes fadeOutRight {
    0% {
      -webkit-transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
}

@keyframes fadeOutRight {
    0% {
      -webkit-transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(50%);
      opacity: 0;
    }
}

@mixin fadeOutRight($object) {
    -webkit-animation:fadeOutRight $object;
    -moz-animation:fadeOutRight $object;
    -ms-animation:fadeOutRight $object;
    animation:fadeOutRight $object;
}

@-webkit-keyframes fadeOutIn {
    0% {
        opacity: 1;
    }
    33%{
        opacity: 0;
    }
    66%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeOutIn {
    0% {
        opacity: 1;
    }
    33%{
        opacity: 0;
    }
    66%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeOutIn {
    0% {
        opacity: 1;
    }
    33%{
        opacity: 0;
    }
    66%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutIn {
    0% {
        opacity: 1;
    }
    33%{
        opacity: 0;
    }
    66%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@mixin fadeOutIn($object) {
    -webkit-animation:fadeOutIn $object;
    -moz-animation:fadeOutIn $object;
    -ms-animation:fadeOutIn $object;
    animation:fadeOutIn $object;
}

@-webkit-keyframes fadeInTop {
    0% {
        top: -20px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@-moz-keyframes fadeInTop {
    0% {
        top: -20px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@-o-keyframes fadeInTop {
    0% {
        top: -20px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes fadeInTop {
    0% {
        top: -20px;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@mixin fadeInTop($object) {
    -webkit-animation:fadeInTop $object;
    -moz-animation:fadeInTop $object;
    -ms-animation:fadeInTop $object;
    animation:fadeInTop $object;
}

/* DPE */
.dpe-content {
    margin-top: 0;
}
.btnDpe {
    font-size: .8em;
    cursor: pointer;
}
.dpeTitle{
  position: absolute;
  bottom: 20px;
  left: 20px;
  @extend %font_default_bold;
  font-size: 14px;
}
.ficheDpe {
    display: block;
    background: #fff;
}
.ficheDpe > span,
.dpeInfoEmi,
.dpeInfoConso {
    background: url('../images/dpe.png');
}
.ficheDpe {
    & > span {
        position: relative;
        display: block;
        float: left;
        width: 265px;
        height: 200px;
    }
    .dpeConso {
        background-position: top left;
        margin-bottom: 50px;
    }
    .dpeEmi {
        color: #000;
        background-position: top right;
        margin-bottom: 50px;
    }
    .dpeConsoEmpty,
    .dpeEmiEmpty{
        position: relative;
        display: block;
        width: 265px;
        height: 212px;
        filter: grayscale(100%);
        &:after{
            content: 'en cours de détermination';
            position: absolute;
            display: block;
            font-size: 20px;
            top: 20%;
            left: 40%;
            text-align: center;
        }
    }
    .dpeEmiEmpty{
        background-position: -263px 0px;
        margin-bottom: 50px;
    }
    .txt {
        position: relative;
        display: inline-block;
        font-family: 'arial';
        font-size: 16px;
        font-weight: bold;
        float: right;
        width: 30%;
        margin-right: 2px;
        text-align: center;
        color:#000;
    }
}
.dpeInfoEmi,
.dpeInfoConso {
    position: absolute;
    display: inline-block;
    right: 0;
    width: 216px;
    height: 38px;
}
.dpeInfoEmi .txt {
    color: #000;
}
.dpeEmpty {
    font-style: italic;
    margin: 5px 0 10px 0 !important;
    text-align: left;
    color: #aaa;
}

/*---- Conso*/
.dpeInfoConso.a {
    top: 20px;
    background-position: 123px 287px;
}
.dpeInfoConso.b {
    top: 46px;
    width: 197px;
    background-position: 103px 287px;
}
.dpeInfoConso.c {
    top: 70px;
    width: 180px;
    background-position: 86px 287px;
}
.dpeInfoConso.d {
    top: 95px;
    width: 160px;
    background-position: 66px 287px;
}
.dpeInfoConso.e {
    top: 120px;
    width: 142px;
    background-position: 48px 287px;
}
.dpeInfoConso.f {
    top: 144px;
    width: 123px;
    background-position: 30px 287px;
}
.dpeInfoConso.g {
    top: 168px;
    width: 99px;
    background-position: 15px 287px;
}
/*---- Emi*/
.dpeInfoEmi {
    background-position-x: right;
}
.dpeInfoEmi.a {
    top: 20px;
    background-position: 123px 287px;
}
.dpeInfoEmi.b {
    top: 46px;
    width: 190px;
    background-position: 103px 287px;
}
.dpeInfoEmi.c {
    top: 70px;
    width: 190px;
    background-position: 86px 287px;
}
.dpeInfoEmi.d {
    top: 95px;
    width: 170px;
    background-position: 66px 287px;
}
.dpeInfoEmi.e {
    top: 117px;
    width: 170px;
    background-position: 76px 288px;
}
.dpeInfoEmi.f {
    top: 142px;
    width: 170px;
    background-position: 76px 288px;
}
.dpeInfoEmi.g {
    top: 166px;
    width: 170px;
    background-position: 70px 290px;
}

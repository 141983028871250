html,
body {
    overflow-x: hidden;
}
body {
    @extend %font_default_regular;
    min-height: 100px;
    color: $font_color_dark2;
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    @include font-size(14px);
}
.lock{
  overflow: hidden;
}
.btn:active:focus, .btn:focus {
    outline: none;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    box-shadow:none;
}
button{
    cursor:pointer;
}
button:focus {
    outline:none;
    outline: -webkit-focus-ring-color auto 0px;
}
a,
a:focus,
a:hover {
    outline: none;
}
a:hover {
    text-decoration: none;
}
button,
a,
a:focus,
a:active,
a:hover,
.encode_decode {
    @include transition(all,120ms,ease-in-out,0s);
}
.encode_decode {
  cursor:pointer;
}
a:focus,
a:active,
a:hover {
    text-decoration: none;
    //color:$font_color_orange;
}
.btn.active,
.btn:active {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}
p {
    @extend %font_default_light;
    line-height: 1.35em;
}


.section_wrapper {
    padding: 60px 0 80px;
}
.section_wrapper_1 {
    background-color: #fff;
}
.section_wrapper_2 {
    background-color: #cacaca;
}
.inner-page {
    display: block;
    width: 135px;
    height: 125px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    display: block;
    margin: 0;
}
.h1,
h1 {
    margin: 0px;
    padding: 0;
    @include font-size(26px);
}
.h2,
h2 {
    font-size: 26px;
    a {
        color: inherit;
        &:hover {
            color: inherit;
        }
    }
}
.h3,
h3 {
    font-size: 24px;
}
.h4,
h4 {
    font-size: 22px;
}
.h5,
h5 {
    font-size: 20px;
}
.h6,
h6 {
    font-size: 18px;
}
ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
}
ul,
li {
    padding: 0;
    list-style-type: none;
}
figure{
  margin-bottom: 0;
}
//Formulaires
label {
    font-weight: inherit;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    line-height: normal;
    vertical-align: middle;
    border: none;
}

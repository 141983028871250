.bg_white{
  background-color: $bg_color_white;
}
.bg_grey{
  background-color: $bg_color_greylight;
}
.bg_darkgrey{
  background-color: $bg_color_darkgrey;
  .desc-title{
    color:$font_color_grey3;
  }
}
//Ken Burns Effect
@-webkit-keyframes mSKenburnsIn {
  from {
    -webkit-transform: translateZ(0px) scale(1.2) translate(5%, 5%);
  }
  to {
    -webkit-transform: translateZ(0px) scale(1) translate(0, 0);
  }
}
@keyframes mSKenburnsIn {
  from {
    transform: translateZ(0px) scale(1.2) translate(5%, 5%);
  }
  to {
    transform: translateZ(0px) scale(1) translate(0, 0);
  }
}
@-webkit-keyframes mSKenburnsOut {
  from {
    -webkit-transform: translateZ(0px) scale(1) translate(0, 0);
  }
  to {
    -webkit-transform: translateZ(0px) scale(1.2) translate(5%, -5%);
  }
}
@keyframes mSKenburnsOut {
  from {
    transform: translateZ(0px) scale(1) translate(0, 0);
  }
  to {
    transform: translateZ(0px) scale(1.2) translate(-5%, -5%);
  }
}
.mSKenburnsIn .mSCover,
.mSKenburnsOut .mSCover {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.340, 0.030, 0.720, 0.990);
  animation-timing-function: cubic-bezier(0.340, 0.030, 0.720, 0.990);
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.mSKenburnsIn .mSCover {
  -webkit-animation-name: mSKenburnsIn;
  animation-name: mSKenburnsIn;
}
.mSKenburnsOut .mSCover {
  -webkit-animation-name: mSKenburnsOut;
  animation-name: mSKenburnsOut;
}
.mSKenburnsIn.slick-active .mSCover,
.mSKenburnsOut.slick-active .mSCover {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slider-home-container{
  position: relative;
}
.slider-home{
  .img_container{
    height: calc(100vh - 32px);
    min-height: 540px;
  }
}
.bloc-slider-container{
  position: absolute;
  bottom: 20px;
  right: 0;
  flex-direction: column;
  opacity: 0;
  @extend %flex;
  @include transition(all,250ms,ease-in-out,0s);
}
.fade-from-left{
  @include fadeInLeft(500ms forwards);
}
.fade-to-right{
  @include fadeOutRight(500ms forwards);
}
.state{
  background-color: $bg_color_white;
  @extend %flex;
  align-self: flex-start;
  padding: 10px 20px;
  @include font-size(18px);
  @extend %font_title_bold;
  color:$font_color_orange;
  .icon{
    margin-right: 10px;
  }
}
.bloc-slider{
  background-color: rgba(0,0,0,0.7);
  color:$font_color_white;
  padding: 30px 200px 30px 50px;
  position: relative;
  .progress{
    position: absolute;
    bottom: 0;
    left: 0;
    width:0%;
    height: 6px;
    background-color: $bg_color_orange;
    border-radius:0;
    @include transform(translateX(0%));
  }
}
.infos{
  .ville{
    @extend %font_title_light;
    @include font-size(30px);
    display: block;
    line-height: 40px;
  }
  .adresse{
    @extend %font_title_light;
    @include font-size(18px);
    display: block;
    line-height: 1em;
    margin-bottom: 20px;
  }
  .title{
    @extend %font_title_bold;
    @include font-size(24px);
    display: block;
    line-height: 1em;
    margin-bottom: 20px;
    color:$font_color_yellow;
  }
}
.liste-triangle{
  @extend %font_default_light;
  @include font-size(20px);
  line-height: 30px;
  margin-top: 30px;
  display: block;
  margin-bottom: 15px;
  li{
    position: relative;
    &:before,
    &:after{
      content:"";
      display: inline-block;
      margin-right: 15px;
      width: 10px;
      height: 12px;
      background-image: url(../images/icones/icon-list-triangle.png);
    }
    &:after{
      background-position: 0 12px;
      position: absolute;
      left: 0;
      top: 9px;
      opacity: 0;
    }
  }
}
.bloc-arrows-container{
  position: absolute;
  bottom: 20px;
  right: 20px;
  @extend %flex;
  .link-detail{
    position: absolute;
    right: 109px;
    bottom: 0;
  }
}
.arrows-container{
  @extend %flex;
  min-width:90px;
}
.btn-square{
  background-color: transparent;
  border:1px solid $bg_color_white;
  @extend %flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  color:$font_color_white;
  @include font-size(37px);
  &:hover,
  &:focus{
    border-color:$bg_color_orange;
    color:$font_color_orange;
  }
}
.slick-prev,
.slick-next{
  &:hover,
  &:focus{
    .icon{
      &:after{
        opacity: 1;
      }
    }
  }
}
.slick-prev{
  margin-right: -1px;
  &:hover,
  &:focus{
    z-index: 1;
  }
}
.section-padding{
  padding: 70px 0 120px;
}
.section-padding2{
  padding: 90px 0 120px;
}
.title-deco{
  &:before{
    content:"";
    width: 84px;
    height: 72px;
    background-image: url('../images/title-deco.png');
    display: block;
    margin: 0 auto 20px;
  }
}
.title-deco-orange{
  &:before{
    background-position: 0 72px;
  }
}
.h1{
  @extend %font_title_light;
  @include font-size(40px);
  color:$font_color_dark2;
  margin-bottom: 40px;
}
.text{
  color:$font_color_grey;
  @include font-size(22px);
  @extend %font_default_light;
  line-height: 30px;
}
.bloc-home{
  display: block;
  .img_container{
    height: 370px;
    &:after{
      content:"";
      background-color: $bg_color_orange;
      mix-blend-mode: multiply;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      @include transition(all,800ms,cubic-bezier(0.165, 0.840, 0.440, 1.000),0s);
    }
  }
  .icon{
    @include transition(all,120ms,ease-in-out,0s);
    right: 0;
    position: relative;
    &:before{
      opacity: 1 !important;
    }
  }
  &:hover,
  &:focus{
    .img_container{
      &:after{
        height: 370px;
      }
    }
    .icon{
      &:before{
        opacity: 1 !important;
      }
    }
  }

  &:active{
    .icon{
      right: -10px;
    }
  }
}
.no-blend-mode{
  .bloc-home{
    .img_container{
      &:after{
        opacity: 0.6;
      }
    }
  }
}
.b-container{
  background-color: $bg_color_orange;
  @extend %flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
}
.b-title{
  color:$font_color_white;
  text-transform: uppercase;
  @include font-size(28px);
  line-height: 30px;
  @extend %font_title_light;
}


//contact
.bloc-contact{
  background-color: $bg_color_white;
  padding: 6px;
}
#map{
  height: 290px;
  margin-bottom: 50px;
}
.horaires{
  @include font-size(16px);
  td:first-of-type{
    padding-right: 20px;
  }
}
.bottom-bloc-contact{
  padding: 0 45px 60px;
}
.contact-adresse{
  @include font-size(30px);
  line-height: 32px;
  @extend %font_default_light;
}
.contact-tel{
  @include font-size(30px);
  color:$font_color_orange;
  margin-bottom: 40px;
  display: block;
  .fa{
    margin-right: 15px;
    @include font-size(24px);
  }
  &:hover,
  &:focus{
    color:$font_color_dark2;
  }
}
.input-container{
  margin-bottom: 24px;
}
.contact-input,
.contact-textarea{
  border:3px solid $bg_color_greylight;
  width: 100%;
  @extend %font_default_regular;
  @include font-size(18px);
}
.contact-input{
  padding: 0 20px;
  height: 55px;
}
.contact-textarea{
  padding: 10px 20px;
  height: 135px;
  margin-bottom: 30px;
}
.btn-default{
  background-color: $bg_color_orange;
  text-transform: uppercase;
  border:none;
  box-shadow:none;
  color:$font_color_white;
  @include font-size(20px);
  @extend %font_title_bold;
  height: 55px;
  padding: 0 70px;
  position: relative;
  .icon{
    position: absolute;
    margin-left: 10px;
    right: 25px;
    top: 50%;
    margin-top: -12px;
    @include transition(all,120ms,ease-in-out,0s);
  }
  &:hover,
  &:focus{
    background-color: $font_color_dark2;
  }
  &:active{
    .icon{
      right: 20px;
    }
  }
}
